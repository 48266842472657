<template>
  <div class="x-wrap">
    <div class="x-page-title">
      <h3>用户账户</h3>
    </div>

    <div class="x-main">
      <!-- 筛选条件 -->
      <div class="x-q-panel">
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="x-con-item">
              <label>账户Id：</label>
              <el-input
                placeholder="请输入账户Id"
                v-model="query.accountId"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="x-con-item">
              <label>关联用户：</label>
              <el-input
                placeholder="请输入关联用户"
                v-model="query.userLike"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="x-con-item">
              <label>开户日期：</label>
              <el-date-picker
                v-model="query.timeArr"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="x-con-item mt25">
              <label>账户余额：</label>
              <el-input
                placeholder="请输入账户余额"
                v-model="query.balanceMin"
              ></el-input
              >一<el-input
                placeholder="请输入账户余额"
                v-model="query.balanceMax"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item mt25">
              <label>银行卡号：</label>
              <el-input
                placeholder="请输入银行卡号"
                v-model="query.bankCardLike"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="x-con-item mt25">
              <label>账户状态：</label>
              <el-select
                placeholder="请选择账户状态"
                v-model="query.accountStatus"
              >
                <el-option label="正常" value="1"></el-option>
                <el-option label="冻结" value="2"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button
              type="primary"
              class="x-q-btn mt25"
              @click="
                query.pageIndex = 1;
                doQuery();
              "
              >筛选</el-button
            >
            <el-button type="text" @click="doQueryReset">重置</el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption">
          <el-button
            icon="el-icon-refresh"
            type="primary"
            plain
            size="small"
            @click="doRefresh"
            >刷新</el-button
          >
        </div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table :data="tableData" stripe style="width: 100%">
            <el-table-column prop="accountId" label="账户Id" width="120">
            </el-table-column>
            <el-table-column prop="realName" label="真实姓名" width="120">
            </el-table-column>
            <el-table-column prop="phoneNumber" label="手机号码" align="center">
            </el-table-column>
            <el-table-column prop="balance" label="可用余额" align="center">
            </el-table-column>
            <el-table-column
              prop="accountStatus"
              label="交易类型"
              align="center"
            >
              <template slot-scope="scope">
                <span class="font-normal" v-if="scope.row.accountStatus == '1'"
                  >正常</span
                >
                <span
                  class="font-normal"
                  v-else-if="scope.row.accountStatus == '2'"
                  >冻结</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="bankCardList" label="绑定银行卡">
              <template slot-scope="scope">
                <span class="font-normal">
                  <div
                    v-for="(item, index) in scope.row.bankCardList"
                    :key="index"
                  >
                    <span>{{ item.bankCardNo }}</span
                    >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span>{{ item.bankName }}</span>
                  </div>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="accountTime"
              label="开户时间"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="180">
              <template slot-scope="scope">
                <div class="flex-box-around">
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="goUpdate(scope.row.id)"
                    >账户详情</el-link
                  >
                  <el-link
                    :underline="false"
                    :type="
                      scope.row.accountStatus === '1' ? 'danger' : 'warning'
                    "
                    @click="
                      handleStatus(scope.row.userId, scope.row.accountStatus)
                    "
                    >{{
                      scope.row.accountStatus === "1" ? "冻结" : "解冻"
                    }}</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AccountQuery, AccStauts } from "@/api/module/balance";

export default {
  name: "AccountIndex",
  data() {
    return {
      showLoading: false,
      query: {
        accountId: "",
        userLike: "",
        balanceMin: "",
        balanceMax: "",
        bankCardLike: "",
        accountStatus: "",
        timeArr: [],
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
    };
  },

  created() {
    this.doQuery();
  },
  methods: {
    async handleStatus(userId, status) {
      let oData = {
        userId,
        status: status === "1" ? "2" : "1",
      };
      const r = await AccStauts(oData);
      if (r.code != 0) return;
      this.$message({
        type: "success",
        message: "操作成功",
        duration: 1300,
        onClose: () => {
          this.doQuery();
        },
      });
    },
    // 查询
    doQuery() {
      let query = this.setQueryParams();
      AccountQuery(query).then((r) => {
        this.total = r.total;
        this.tableData = r.list;
      });
    },

    // 设置筛选
    setQueryParams() {
      let query = `?pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}`;
      if (this.query.accountId) {
        query += `&accountId=${this.query.accountId}`;
      }
      if (this.query.userLike) {
        query += `&userLike=${this.query.userLike}`;
      }
      if (this.query.balanceMin) {
        query += `&balanceMin=${this.query.balanceMin}`;
      }
      if (this.query.balanceMax) {
        query += `&balanceMax=${this.query.balanceMax}`;
      }
      if (this.query.bankCardLike) {
        query += `&bankCardLike=${this.query.bankCardLike}`;
      }
      if (this.query.accountStatus) {
        query += `&accountStatus=${this.query.accountStatus}`;
      }
      if (this.query.timeArr.length) {
        query += `&startTime=${this.query.timeArr[0]}&endTime=${this.query.timeArr[1]}`;
      }
      return query;
    },

    // 重置筛选
    doQueryReset() {
      this.query = {
        type: "",
        id: "",
        orderId: "",
        fromLike: "",
        toLike: "",
        timeArr: [],
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },

    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },

    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>



